<!--门店赠金-->
<template>
    <div class="balance">
        <div style="margin-bottom: 20px;">门店总赠金金额：￥{{ allGiveAmount||0 }}</div>
      <!-- 查詢 -->
      <!-- 表格 -->
      <el-table
        :data="tableData"
        border
        stripe
        :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
        }"
      >
        <el-table-column label="充值门店" align="center"  prop="shopName">
        </el-table-column>
        <el-table-column label="门店充值总本金" align="center">
          <template slot-scope="scope">
          <span>{{'￥ '+scope.row.totalBalance }}</span>
        </template>
        </el-table-column>
        <el-table-column label="已赠送门店总赠金" align="center">
          <template slot-scope="scope">
          <span>{{'￥ '+scope.row.totalGiveAmount }}</span>
        </template>
        </el-table-column>
        <el-table-column label="门店剩余赠金" align="center">
          <template slot-scope="scope">
          <span>{{'￥ '+scope.row.totalGiveAmountResidue }}</span>
        </template>
        </el-table-column>
        <el-table-column label="充值次数" align="center" prop="rechargeNum">
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <section class="table-footer">
        <el-pagination
          layout="total,sizes, prev, pager, next,jumper"
          background
          :total="total"
          :page-size="tableParams.size"
          @size-change="onSizeChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="currentPage"
          @current-change="onCurrentPage"
        >
        </el-pagination>
      </section>
    </div>
  </template>
  <script>
  import { pickerOptions } from "@/db/objs";
  import { shopGiveAmount,shopGiveAllAmount } from "@/api/user/user.js";
  import { getIdroomList } from "@/api/order/order";
  
  export default {
    data() {
      return {
        pickerOptions,
        dateToDate: [], //日期
        tableParams: {
          page: 1,
          size: 10,
          memberId: "",
        },
        tableData: [],
        total: 0,
        currentPage: 1,
        allGiveAmount:''
      };
    },
    created() {
      this.tableParams.memberId = this.$route.query.memberId;
    },
    mounted() {
      this.shopGiveAmount();
      this.getAllAmount();
      this.getIdroomList(); //【请求】房间列表
    },
    methods: {
      // 总赠金
      getAllAmount() {
        shopGiveAllAmount(this.tableParams).then((res) => {
          if (res.isSuccess == "yes") {
            this.allGiveAmount = res.data.totalGiveAmount
          }
        });
      },
      // table列表
      shopGiveAmount() {
       
        shopGiveAmount(this.tableParams).then((res) => {
          if (res.isSuccess == "yes") {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        });
      },
      // 【请求】房间列表
      getIdroomList() {
        let data = this.tableParams;
        getIdroomList(data).then((res) => {
          if (res.isSuccess == "yes") {
            this.roomList = res.data;
          }
        });
      },
      // 【监听】表格重载
      reload() {
        this.tableParams.page = 1;
        this.currentPage = 1;
        this.shopGiveAmount();
      },
      // 【监听】表格分页点击
      onCurrentPage(page) {
        this.tableParams.page = page;
        this.shopGiveAmount();
      },
  
      // 【监听】表格条数点击
      onSizeChange(size) {
        this.tableParams.size = size;
        this.reload();
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .balance {
    padding: 0.15rem;
    box-sizing: border-box;
  }
  
  .table-search {
    display: flex;
    margin-bottom: 0.15rem;
  }
  
  .search_L {
    display: flex;
  }
  
  .table-footer {
    margin-top: 0.1rem;
    display: flex;
    justify-content: flex-end;
  }
  
  .form-footer {
    display: flex;
    justify-content: flex-end;
  }
  </style>
  